@font-face {
  font-family: Neue;
  src: url(../fonts/HelveticaNeue-CondensedBlack.ttf);
}

@font-face {
  font-family: Neue;
  src: url(../fonts/HelveticaNeue-CondensedBold.ttf);
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  font-family: Neue, Helvetica, sans-serif;
  background: #000;
}

.webBox {
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
}

h1 {
  position: absolute;
  left: 0;
  top: -200px;
}

h2 {
  position: relative;
  margin: 0;
  font-size: 9vw;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.05;

  @media (max-width: 991px) {
    font-size: 90px;
  }

  @media (max-width: 499px) {
    margin: 20px 0;
    font-size: 18vw;
  }
}

h3 {
  font-size: 30px;
  line-height: 2.4;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 26px;
  }

  @media (max-width: 499px) {
    font-size: 22px;
  }
}

p {
  font-size: 2.1vw;
  font-weight: bold;
  color: #fff;
  line-height: 1.5;

  @media (max-width: 991px) {
    font-size: 22px;
  }

  @media (max-width: 499px) {
    font-size: 16px;
  }
}

header {
  position: relative;
  padding: 3vw 5vw;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1249px) {
    padding: 3vw 5vw 0vw;
  }

  .logo {
    width: 29vw;
    height: auto;

    @media (max-width: 1249px) {
      width: 35vw;
    }
  }

  .headers {
    position: relative;
    margin: 8vw 0;
    z-index: 2;
    overflow: hidden;

    @media (max-width: 991px) {
      margin: 6vw 0;
    }
  }

  .par {
    position: relative;
    margin-bottom: 9vw;
    z-index: 2;
  }

  .par1,
  .par2,
  .par3,
  .par4,
  .par5 {
    opacity: 0;

    &.selected {
      animation: show-anim 2s linear forwards;
    }
  }

  p {
    width: 100%;
    text-align: center;
  }
}

@keyframes show-anim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.line {
  position: absolute;

  &.first {
    top: 10vw;
    left: 9vw;
    width: 100vw;
    height: auto;
    fill: red;
    fill-rule: evenodd;
  }

  &.second {
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    fill: red;
    fill-rule: evenodd;

    @media (max-width: 991px) {
      left: -9px;
    }
  }

  &.third {
    top: -1.3vw;
    left: 13vw;
    width: 67vw;
    height: auto;
    fill: #090909;
    fill-rule: evenodd;
  }

  &.fourth {
    top: -1.5vw;
    left: 0;
    width: 80vw;
    height: auto;
    fill: #090909;
    fill-rule: evenodd;
  }

  &.fifth {
    top: -2.8vw;
    left: -2vw;
    width: 80vw;
    height: auto;
    fill: red;
    fill-rule: evenodd;
    z-index: 2;
    transform: rotate(2deg);
  }

  &.sixth {
    top: 6.2vw;
    left: 38.93vw;
    width: 60vw;
    height: auto;
    fill: red;
    fill-rule: evenodd;
  }
}

.red {
  position: relative;
  padding: 0 10vw;
  width: 100%;
  height: 64vw;
  color: #fff;
  background: #ff0000;
  overflow: hidden;

  .found {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;

    &.selected {
      animation: show-anim 2s linear forwards;
    }

    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
    }

    span {
      font-size: 2.1vw;

      @media (max-width: 991px) {
        font-size: 22px;
      }

      @media (max-width: 499px) {
        font-size: 16px;
      }
    }

    .club {
      font-size: 3.65vw;

      @media (max-width: 991px) {
        font-size: 28px;
      }

      @media (max-width: 499px) {
        font-size: 22px;
      }
    }
  }
}

.black {
  position: relative;
  padding: 0 5vw;
  font-weight: bold;

  .imageSection {
    margin-top: -9vw;

    img {
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }

  .firstPart {
    display: flex;
    align-items: flex-end;

    @media (max-width: 991px) {
      order: 2;
      z-index: 5;
    }
  }

  .black1,
  .black2,
  .black3,
  .black4 {
    opacity: 0;

    &.selected {
      animation: show-anim 2s linear forwards;
    }
  }

  img {
    width: 100%;
  }

  p {
    position: relative;
    padding: 0 5vw 5vw;
    font-size: 1.3vw;
    background: #000;
    z-index: 5;

    @media (max-width: 991px) {
      font-size: 22px;
      background: transparent;
    }

    @media (max-width: 499px) {
      font-size: 16px;
    }

    &.rightColPar {
      width: 67.5%;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    &.black4 {
      background: transparent;
    }
  }

  .join {
    display: block;
    margin: 5vw auto;
    width: 63%;

    @media (max-width: 991px) {
      width: 100%;
    }

    p {
      padding: 0;
      font-size: 2.21vw;

      @media (max-width: 991px) {
        font-size: 22px;
      }

      @media (max-width: 499px) {
        font-size: 16px;
      }

      &.redPar {
        font-size: 7.3vw;
        color: #ff0000;
        text-transform: uppercase;
        line-height: 0.8;
        font-weight: normal;
        opacity: 0;

        &.selected {
          animation: show-anim 2s linear forwards;
        }

        @media (max-width: 991px) {
          font-size: 11.7vw;
        }
      }

      &.lastPar {
        text-align: right;
      }

      &.infoPar {
        margin: 10vw 0;
        text-align: center;
        font-size: 1.3vw;

        @media (max-width: 991px) {
          font-size: 22px;
        }

        @media (max-width: 499px) {
          font-size: 16px;
        }

        a {
          color: #fff;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 137px;
  color: #fff;
  background: #ff0000;
  font-size: 24px;
  font-weight: bold;

  @media (max-width: 499px) {
    height: 77px;
    font-size: 16px;
  }

  span {
    color: #000;
    text-transform: uppercase;
  }
}
